import React, { useEffect, useState } from 'react';
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Spinner,
  OverlayTrigger, Tooltip, Table
} from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Form, ErrorMessage } from 'formik';
import Datetime from 'react-datetime';
import { AdminRoutes } from '../../../routes';
import { getDateTime, getDateTimeByYMD } from '../../../utils/dateFormatter';
import Trigger from '../../../components/OverlayTrigger';
import ReactCreatable from '../../../components/ReactSelectField/ReactCreatable';
import { SketchPicker } from 'react-color';
import { PACKAGE_USER_FILTER } from '../constants';
import { useGetPackageUserFilterQuery } from '../../../reactQuery/hooks/customQueryHook';


const PackageCreateForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  loading,
  navigate,
  setFieldValue,
  packageData,
  isEdit = false,
  typeOptions,
  typeValue,
  setTypeValue,
  isSelectLoading,
  handleCreateOption,
  selectedUserIds,
  setSelectedUserIds
}) => {
  const { t } = useTranslation(['packages']);

  // Create a state variable to store the selected color
  const [selectedColor, setSelectedColor] = useState(''); // Initialize with a default color
  const [selectedColorBackground, setSelectedColorBackground] = useState(''); // Initialize with a default color
  const yesterday = new Date(Date.now() - 86400000);
  useEffect(() => {
    if (packageData && packageData?.textColor) {
      setSelectedColor(packageData?.textColor)
    }
    if (packageData && packageData?.backgroundColor) {
      setSelectedColorBackground(packageData?.backgroundColor)
    }
  }, [packageData])

  // Handler function to update the selected color
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    values.textColor = selectedColor;
  };

  // Handler function to update the selected color
  const handleColorChangeBackground = (color) => {
    setSelectedColorBackground(color.hex);
    values.backgroundColor = selectedColorBackground;
  };

  const { data } = useGetPackageUserFilterQuery({
    params: {
      type: values.filterType,
      operator: values.filterOperator,
      value: values.filterValue || packageData?.moreDetails?.filterValue,
    },
    enabled: !!(values.filterType && values.filterOperator ),
  });

  useEffect(() => {
    if (values.amount) {
      const roundedScCoin = Math.ceil(values.amount);
      const roundedGcCoin = Math.ceil(values.amount) * 1000;

      setFieldValue('scCoin', roundedScCoin);
      setFieldValue('gcCoin', roundedGcCoin);
    } else {
      // Reset the values when amount is cleared
      setFieldValue('scCoin', '');
      setFieldValue('gcCoin', '');
    }
  }, [values.amount, setFieldValue]);

  useEffect(() => {
    if (values.bonusSc) {
      const roundedBonusGc = Math.ceil(values.bonusSc) * 1000;
      setFieldValue('bonusGc', roundedBonusGc);
    } else {
      // Reset the values when amount is cleared
      setFieldValue('bonusGc', 0);
    }
  }, [values.bonusSc, setFieldValue]);



  useEffect(() => {
    if (packageData) {
      setFieldValue('filterType', packageData?.moreDetails?.filterType || '');
      setFieldValue('filterOperator', packageData?.moreDetails?.filterOperator || '=');
      setFieldValue('filterValue', packageData?.moreDetails?.filterValue || 0);
      setSelectedUserIds(packageData?.playerId || []);
    }
  }, [packageData, setFieldValue]);

  const handleSelectUser = (e, userId) => {
    const updatedSelectedUserIds = e.target.checked
      ? [...selectedUserIds, userId]
      : selectedUserIds.filter(id => id !== userId);
    setSelectedUserIds(updatedSelectedUserIds);
  };

  const handleSelectAll = (e) => {
    const allUserIds = data?.playerIds?.map(user => user.userId);
    setSelectedUserIds(e.target.checked ? allUserIds : []);
  };
  return (
    <>
      <Form>
        <Row>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.welcomePurchaseBonusApplicable")}
              {/* <span className='text-danger'> *</span> */}
            </BForm.Label>

            <BForm.Check
              type="switch"
              name="welcomePurchaseBonusApplicable"
              // placeholder='Enter User Name'
              checked={values.welcomePurchaseBonusApplicable}
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  setFieldValue("isSpecialPackage", false);
                  setFieldValue("firstPurchaseApplicable", false);
                  setFieldValue("purchaseLimitPerUser", 0);
                  setFieldValue("firstPurchaseScBonus", 0);
                  setFieldValue("firstPurchaseGcBonus", 0);
                }
              }}
              onBlur={handleBlur}
            />

            <ErrorMessage
              component="div"
              name="welcomePurchaseBonusApplicable"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              Timer for Special Purchase (minutes)
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="welcomePurchaseBonusApplicableMinutes"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t(
                "createPackage.inputFields.welcomePurchaseBonusApplicableMinutes.placeholder"
              )}
              value={values.welcomePurchaseBonusApplicableMinutes}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values.welcomePurchaseBonusApplicable}
            />

            <ErrorMessage
              component="div"
              name="welcomePurchaseBonusApplicableMinutes"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              Welcome Purchase Percentage
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="welcomePurchasePercentage"
              min="0"
              placeholder={"Welcome Purchase Percentage"}
              value={values.welcomePurchasePercentage}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values.welcomePurchaseBonusApplicable}
            />

            <ErrorMessage
              component="div"
              name="welcomePurchasePercentage"
              className="text-danger"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <BForm.Label>
              {`${t("createPackage.inputFields.amountField.label")} ( $ )`}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="amount"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              placeholder={t(
                "createPackage.inputFields.amountField.placeholder"
              )}
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />

            <ErrorMessage
              component="div"
              name="amount"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.gcField.label")}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="gcCoin"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t("createPackage.inputFields.gcField.placeholder")}
              value={values.gcCoin}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />

            <ErrorMessage
              component="div"
              name="gcCoin"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.scField.label")}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="scCoin"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t("createPackage.inputFields.scField.placeholder")}
              value={values.scCoin}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />

            <ErrorMessage
              component="div"
              name="scCoin"
              className="text-danger"
            />
          </Col>

          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.bonusSc.label")}
            </BForm.Label>

            <BForm.Control
              type="number"
              name="bonusSc"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t("createPackage.inputFields.bonusSc.placeholder")}
              value={values.bonusSc}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />

            <ErrorMessage
              component="div"
              name="bonusSc"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.bonusGc.label")}
            </BForm.Label>

            <BForm.Control
              type="number"
              name="bonusGc"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t("createPackage.inputFields.bonusGc.placeholder")}
              value={values.bonusGc}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />

            <ErrorMessage
              component="div"
              name="bonusGc"
              className="text-danger"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <BForm.Label>
              Package purchase limit per user
              <span className="text-danger"> *</span>
            </BForm.Label>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  Enter zero if you do not want to add a limit.
                </Tooltip>
              }
            >
              <BForm.Control
                type="number"
                name="purchaseLimitPerUser"
                min="0"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
                placeholder={t(
                  "createPackage.inputFields.purchaseLimitPerUser.placeholder"
                )}
                value={values.purchaseLimitPerUser}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.welcomePurchaseBonusApplicable}
              />
            </OverlayTrigger>
            <ErrorMessage
              component="div"
              name="purchaseLimitPerUser"
              className="text-danger"
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <BForm.Label>
                  {t("createPackage.inputFields.active")}
                  <span className="text-danger"> *</span>
                </BForm.Label>

                <BForm.Check
                  type="switch"
                  name="isActive"
                  // placeholder='Enter Ac'
                  checked={values.isActive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />

                <ErrorMessage
                  component="div"
                  name="isActive"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>
                  {t("createPackage.inputFields.visibleInStore")}
                  <span className="text-danger"> *</span>
                </BForm.Label>

                <BForm.Check
                  type="switch"
                  name="isVisibleInStore"
                  // placeholder='Enter User Name'
                  checked={values.isVisibleInStore}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="isVisibleInStore"
                  className="text-danger"
                />
              </Col>


            </Row>
          </Col>


          <Row className="mt-3">
            <Col>
              <BForm.Label>Valid From</BForm.Label>
              <BForm.Check
                type="switch"
                name="isValidFrom"
                checked={values.isValidFrom}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isEdit}
              />
              <ErrorMessage
                component="div"
                name="isValidFrom"
                className="text-danger"
              />
            </Col>
            {values.isValidFrom === true ? (
              <>
                <Col>
                  <BForm.Label>
                    {t("createPackage.inputFields.validFrom")}{" "}
                  </BForm.Label>
                  <Datetime
                    inputProps={{
                      placeholder: "MM-DD-YYYY HH:MM",
                    }}
                    dateFormat="MM-DD-YYYY"
                    onChange={(e) => {
                      setFieldValue("validFrom", e);
                    }}
                    value={values.validFrom ? getDateTime(values.validFrom): values.validFrom}
                    isValidDate={(e) => {
                      return (
                        e._d > yesterday ||
                        getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                      );
                    }}
                    timeFormat={true}
                  />
                  <ErrorMessage
                    component="div"
                    name="validFrom"
                    className="text-danger"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <BForm.Label>
                    {t("createPackage.inputFields.validFrom")}{" "}
                  </BForm.Label>

                  <BForm.Control
                    type="number"
                    name="validFrom"
                    min="0"
                    disabled="true"
                  />

                  <ErrorMessage
                    component="div"
                    name="validFrom"
                    className="text-danger"
                  />
                </Col>
              </>
            )}
            <Col>
              <BForm.Label>Valid Until</BForm.Label>
              <BForm.Check
                type="switch"
                name="isValidUntil"
                checked={values.isValidUntil}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isEdit}
              />
              <ErrorMessage
                component="div"
                name="isValidUntil"
                className="text-danger"
              />
            </Col>

            {values.isValidUntil === true ? (
              <Col>
                <BForm.Label>
                  {t("createPackage.inputFields.validTill")}{" "}
                </BForm.Label>
                <Datetime
                  inputProps={{
                    placeholder: "MM-DD-YYYY HH:MM",
                  }}
                  dateFormat="MM-DD-YYYY"
                  onChange={(e) => {
                    setFieldValue("validTill", e);
                  }}
                  value={values.validTill ? getDateTime(values.validTill): values.validTill}
                  isValidDate={(e) => {
                    return (
                      e._d > yesterday ||
                      getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                    );
                  }}
                  timeFormat={true}
                />
                <ErrorMessage
                  component="div"
                  name="validTill"
                  className="text-danger"
                />
              </Col>
            ) : (
              <>
                <Col>
                  <BForm.Label>
                    {t("createPackage.inputFields.validTill")}{" "}
                  </BForm.Label>

                  <BForm.Control
                    type="number"
                    name="validTill"
                    min="0"
                    disabled="true"
                  />

                  <ErrorMessage
                    component="div"
                    name="validTill"
                    className="text-danger"
                  />
                </Col>
              </>
            )}

          </Row>

        </Row>

        <Row className="mt-3">
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.firstPurchaseApplicable")}
            </BForm.Label>
            <BForm.Check
              type="switch"
              name="firstPurchaseApplicable"
              checked={values.firstPurchaseApplicable}
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  console.log('e.target.checked: ', e.target.checked);
                  setFieldValue("firstPurchaseScBonus", 0);
                  setFieldValue("firstPurchaseGcBonus", 0);
                }
              }}
              onBlur={handleBlur}
              disabled={values.welcomePurchaseBonusApplicable}
            />
            <ErrorMessage
              component="div"
              name="firstPurchaseApplicable"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.firstPurchaseScBonus.label")}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="firstPurchaseScBonus"
              min="1"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t(
                "createPackage.inputFields.firstPurchaseScBonus.placeholder"
              )}
              value={values.firstPurchaseScBonus}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values.firstPurchaseApplicable}
            />

            <ErrorMessage
              component="div"
              name="firstPurchaseScBonus"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.firstPurchaseGcBonus.label")}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Control
              type="number"
              name="firstPurchaseGcBonus"
              min="1"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              placeholder={t(
                "createPackage.inputFields.firstPurchaseGcBonus.placeholder"
              )}
              value={values.firstPurchaseGcBonus}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values.firstPurchaseApplicable}
            />

            <ErrorMessage
              component="div"
              name="firstPurchaseGcBonus"
              className="text-danger"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.specialPackage")}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Check
              type="switch"
              name="isSpecialPackage"
              // placeholder='Enter User Name'
              checked={values.isSpecialPackage}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.welcomePurchaseBonusApplicable}
            />

            <ErrorMessage
              component="div"
              name="isSpecialPackage"
              className="text-danger"
            />
          </Col>
          <Col>
            <BForm.Label>
              {t("createPackage.inputFields.thumbnail.label")}
              <span className="text-danger"> *</span>
            </BForm.Label>

            <BForm.Text>
              <Trigger
                message={t("createPackage.inputFields.thumbnail.message")}
                id={"mes"}
              />
              <input
                id={"mes"}
                title=" "
                name="image"
                type="file"
                onChange={(event) => {
                  setFieldValue("image", event.currentTarget.files[0]);
                }}
              />
              {values?.image && (
                <img
                  alt="not found"
                  width="100px"
                  src={URL.createObjectURL(values.image)}
                />
              )}
              {!values?.image && packageData?.imageUrl && (
                <img alt="not found" width="60px" src={packageData.imageUrl} />
              )}
            </BForm.Text>

            <ErrorMessage
              component="div"
              name="image"
              className="text-danger"
            />
          </Col>
          <Col></Col>
        </Row>

        <div className="mt-4 d-flex justify-content-between align-items-center">
          <Button
            variant="warning"
            onClick={() => navigate(AdminRoutes.Packages)}
          >
            {t("createPackage.cancelButton")}
          </Button>

          <Button
            variant="success"
            onClick={() => {
              handleSubmit();
            }}
            className="ml-2"
            disabled={loading}
          >

            {loading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (<>{t("createPackage.submitButton")}</>)
            }
          </Button>
        </div>

        <Row className="mt-3">
          <Col>
            <BForm.Label>Package User Filter</BForm.Label>
            <BForm.Select
              as="select"
              name="filterType"
              value={values.filterType}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select Filter Type</option>
              {Object.entries(PACKAGE_USER_FILTER).map(([label, val]) => (
                <option key={val} value={val}>
                  {label}
                </option>
              ))}
            </BForm.Select>
            <ErrorMessage component="div" name="filterType" className="text-danger" />
          </Col>
          <Col>
            <BForm.Label>Operator</BForm.Label>
            <BForm.Select
              as="select"
              name="filterOperator"
              value={values.filterOperator}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select Operator</option>
              <option value="=">=</option>
              <option value=">">&gt;</option>
              <option value="<">&lt;</option>
              <option value=">=">&gt;=</option>
              <option value="<=">&lt;=</option>
            </BForm.Select>
            <ErrorMessage component="div" name="filterOperator" className="text-danger" />
          </Col>
          <Col>
            <BForm.Label>Value</BForm.Label>
            <BForm.Control
              type="number"
              onKeyDown={(evt) =>
                ["e", "E", "+"].includes(evt.key) && evt.preventDefault()
              }
              name="filterValue"
              value={values.filterValue}
              onChange={handleChange}
              placeholder="Enter Filter Value"
              onBlur={handleBlur}
              disabled={isEdit}
            />
            <ErrorMessage component="div" name="filterValue" className="text-danger" />
          </Col>
        </Row>

        <Row className="mt-3">
          <BForm.Label>User Details</BForm.Label>
          <Col md={2}>
            {selectedUserIds?.length > 0 && (
              <Button
                variant="primary"
                onClick={() => setSelectedUserIds([])}
                style={{ width: "100px", marginTop: "20px" }}
              >
                Clear
              </Button>
            )}
          </Col>

          <div>
            {data?.playerIds?.length !== 0 ? (
              <Table
                bordered
                striped
                hover
                size="sm"
                className="text-center mt-4"
              >
                <thead className="thead-dark">
                  <tr>
                    <th>
                      <input
                        name="selectAll"
                        type="checkbox"
                        className="form-check-input cursor-pointer"
                        onChange={handleSelectAll}
                        checked={
                          selectedUserIds.length === data?.playerIds?.length
                        }
                      />
                    </th>
                    <th>User ID</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Is Active</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.playerIds?.map((user) => (
                    <tr key={user.userId}>
                      <td>
                        <input
                          name="select"
                          type="checkbox"
                          className="form-check-input cursor-pointer"
                          checked={selectedUserIds.includes(user.userId)}
                          onChange={(e) => handleSelectUser(e, user.userId)}
                        />
                      </td>
                      <td>{user.userId}</td>
                      <td><a href="#" onClick={() => {
                        navigate(
                          `${AdminRoutes.PlayerDetails.split(':').shift()}${user.userId}`
                        )
                      }}>{user.email}</a></td>
                      <td>{user.username}</td>
                      <td>{user.isActive ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="text-danger text-center mt-3">No Data Available</p>
            )}
            <div></div>
          </div>
        </Row>

        {/* <ColorPickerForm /> */}
      </Form>
    </>
  );
};

export default PackageCreateForm;
