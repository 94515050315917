import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Col, Row, Form as BForm, Button, Spinner, Table, OverlayTrigger, Tooltip } from "@themesberg/react-bootstrap";
import Datetime from "react-datetime";
import { getDateTimeByYMD } from "../../../utils/dateFormatter.js";
import { AdminRoutes } from "../../../routes.js";
import { useNavigate, useParams } from "react-router-dom";
import { editPromoSchema } from "../schemas.js";
import useEditPromoCode from "../hooks/useEditPromoCode.js";

const EditPromoCode = () => {
  const navigate = useNavigate();
  const { promocodeId } = useParams();
  const yesterday = new Date(Date.now());



  const {promoDetail, packageData, updatePromoCode, handleEditPromotionBonusSubmit, selectedId, setSelectedId,handleSelectAll,handleAddGame, isInitialValues, updateLoading} = useEditPromoCode(promocodeId);

  useEffect(() => {
    if (promoDetail?.package) {
      setSelectedId(promoDetail.package);
    }else{
      setSelectedId([]);
    }
  }, [promoDetail]);

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Edit Purchase Promo Codes</h3>
        </Col>
      </Row>

      <Formik
        enableReinitialize={isInitialValues}
        initialValues={{
          promocode: promoDetail?.promocode || '',
          isActive: promoDetail?.isActive || false,
          validTill: promoDetail?.validTill ? new Date(promoDetail.validTill) : new Date(Date.now() + 86400000),
          maxUsersAvailed: promoDetail?.maxUsersAvailed || 0,
          maxUsersAvailedCount: promoDetail?.maxUsersAvailedCount,
          perUserLimit: promoDetail?.perUserLimit || 0,
          isDiscountOnAmount: promoDetail?.isDiscountOnAmount || false,
          discountPercentage: promoDetail?.discountPercentage || 0,
          isValidUntil: !!promoDetail?.validTill
        }}
        validationSchema={editPromoSchema(promoDetail?.maxUsersAvailedCount)}
        onSubmit={handleEditPromotionBonusSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Row>
              <Col>
                <BForm.Label>Promocode
                <span className="text-danger"> *</span>
                </BForm.Label>
                <BForm.Control
                  type="text"
                  name="promocode"
                  placeholder="Enter Promocode"
                  value={values.promocode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="promocode"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Discount on Amount</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="isDiscountOnAmount"
                  checked={values.isDiscountOnAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col>
                <BForm.Label>
                  {values.isDiscountOnAmount ? "Discount Percentage On Amount" : "Bonus Coins percentage"}
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Enter zero if you do not want to add a limit.
                    </Tooltip>
                  }
                >
                <BForm.Control
                  type="number"
                  name="discountPercentage"
                  placeholder="Enter Percentage"
                  min="0"
                  onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                  max="100"
                  value={values.discountPercentage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="discountPercentage"
                  className="text-danger"
                />
              </Col>
            </Row>           

            <Row className="mt-3">
              <Col>
                <BForm.Label>Max Users Availed (Zero as none limit)
                <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Enter zero if you do not want to add a limit.
                    </Tooltip>
                  }
                >
                <BForm.Control
                  type="number"
                  name="maxUsersAvailed"
                  placeholder="Enter Max Users Availed"
                  min="0"
                  onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                  value={values.maxUsersAvailed}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="maxUsersAvailed"
                  className="text-danger"
                />
              </Col>
              
              <Col>
                <BForm.Label>Per Promocode User Limit (Zero as none limit)
                <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Enter zero if you do not want to add a limit.
                    </Tooltip>
                  }
                  >
                <BForm.Control
                  type="number"
                  name="perUserLimit"
                  placeholder="Enter Per User Limit"
                  min="0"
                  onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                  value={values.perUserLimit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="perUserLimit"
                  className="text-danger"
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={2}>
                <BForm.Label>Active</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="isActive"
                  checked={values.isActive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>

              <Col md={2}>
                <BForm.Label>Valid Until</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="isValidUntil"
                  checked={values.isValidUntil}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              {values.isValidUntil && (
                <Col md={4} lg={3}>
                  <BForm.Label>Valid Till</BForm.Label>
                  <Datetime
                    inputProps={{
                      placeholder: "MM-DD-YYYY",
                      disabled: false,
                    }}
                    dateFormat="MM/DD/YYYY"
                    onChange={(e) => setFieldValue("validTill", e)}
                    value={values.validTill}
                    isValidDate={(e) => e._d > yesterday || getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())}
                    timeFormat={true}
                  />
                </Col>
              )}
            </Row>

            <Row className="mt-3">
              <BForm.Label>Package Details</BForm.Label>
              <Col md={2}>
                {selectedId?.length > 0 && (
                  <Button variant='primary' onClick={() => setSelectedId([])} style={{ width: '100px', marginTop: "20px" }}>Clear</Button>
                )}
              </Col>
              <div style={{ overflow: 'auto' }}>
                {packageData ? (<>
                  <Table bordered striped hover size='sm' className='text-center mt-4'>
                    <thead className='thead-dark'>
                      <tr>
                        <th>
                          <input
                            name='selectAll'
                            type='checkbox'
                            className='form-check-input cursor-pointer'
                            checked={selectedId?.length > 0 && selectedId?.length === packageData?.rows?.length}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th>Package ID</th>
                        <th>Amount</th>
                        <th>GC + Bonus GC Coin</th>
                        <th>SC + Bonus SC Coin</th>
                        <th>Package Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packageData?.rows?.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            <input
                              name='select'
                              type='checkbox'
                              className='form-check-input cursor-pointer'
                              checked={selectedId.includes(item.packageId)}
                              onChange={(e) => handleAddGame(e, item)}
                            />
                          </td>
                          <td>{item.packageId}</td>
                          <td>{item.amount}</td>
                          <td>{item.gcCoin} + {item.bonusGc}</td>
                          <td>{item.scCoin} + {item.bonusSc} </td>
                          <td>{item.welcomePurchaseBonusApplicable ? 'Welcome Purchase Package' : item.firstPurchaseApplicable && item.isSpecialPackage ? 'Special First Purchase Package' :  item.firstPurchaseApplicable ? 'First Purchase Package' : item.isSpecialPackage ? 'Special Package' : 'Basic Package'}</td>
                          <td>{item.isActive ? "Active" : "Inactive"}</td>
                        </tr>
                      ))}
                      {packageData?.rows?.length === 0 && (
                        <tr><td className='text-danger' colSpan={6}>No Data Available</td></tr>
                      )}
                    </tbody>
                  </Table>
                  <BForm.Label>Note: The promo code is not applicable to any special bonus packages.</BForm.Label>
                  </>
                ) : (
                  <p className='text-danger text-center mt-3'>No Data Available</p>
                )}
              </div>
            </Row>

            <div className="mt-4 d-flex justify-content-between align-items-center">
              <Button
                variant="warning"
                onClick={() => navigate(AdminRoutes.PromoCodeBonus)}
              >
                Cancel
              </Button>

              <Button
                variant="success"
                onClick={handleSubmit}
                disabled={updateLoading}
              >
                Submit
                {updateLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPromoCode;
