import {
  Col,
  Row,
  Form,
  Button,
  Table,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { minPendingDays, statusOptions, tableHeaders } from "./constants";
import DateRangePicker from "../../components/DateRangePicker";
import { formattedDate, getDateDaysAgo, getDateThreeMonthsBefore, getDateTime, getStartDateMonthsAgo } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCheckSquare, faWindowClose, faArrowCircleUp, faArrowCircleDown, faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  faCheck,
  faFileDownload,
  faRedoAlt,
  faTimesSquare,
} from "@fortawesome/free-solid-svg-icons";
import Trigger from "../../components/OverlayTrigger";
import useWithdrawTransactions from "./hooks/useWithdrawTransactions";
import { InlineLoader } from "../../components/Preloader";
import PaginationComponent from "../../components/Pagination";
import { Link } from "react-router-dom";
import { ApproveRedeemConfirmation, RedeemMoreDetail } from "../../components/ConfirmationModal";
import { convertTimeZone, convertToTimeZone, getFormattedTimeZoneOffset, onDownloadCsvClick } from "../../utils/helper";
import { getItem } from "../../utils/storageUtils";
import { timeZones } from "../Dashboard/constants";
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime'
import PreApprovedRedeemRequests from "./Components/PreApprovedRedeemRequests";
import LongPendingRequests from "./Components/LongPendingRequests";
import LineChart from "./Components/LineChart";
import BarChart from "./Components/BarChart";
import { useQuery } from '@tanstack/react-query'
import { getRedeemReport } from "../../utils/apiCalls";
import { useUserStore } from "../../store/store";

const WithdrawRequests = () => {
  const [type, setType] = useState('')
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const { permissions } = useUserStore((state) => state);

  const {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedAction,
    startDate, setStartDate, endDate, setEndDate,
    transactionData,
    loading,
    search,
    setSearch,
    updateWithdrawData,
    approveModal,
    setApproveModal,
    redeemRequest,
    setRedeemRequest,
    updateLoading,
    handelFetchStatus,
    getCsvDownloadUrl,
    setRedeemMoreDetail,
    redeemMoreDetail,
    moreDetailData,
    setMoreDetailData,
    timezoneOffset,
    selected,
    orderBy,
    setOrderBy,
    sort,
    setSort,
    over, setOver,
    getMoreDetail,
    reasonData,
    setReasonData,
    updateRedeemRequestApproved,
    setIsApproved,
  } = useWithdrawTransactions();

  const handleRedeem = () => {
  }

  const handleApproveRequest = () => {
    updateWithdrawData({
      withdrawRequestId: redeemRequest?.withdrawRequestId.toString(),
      reason: "",
      userId: redeemRequest?.userId,
      status: type,
    });
  }

  const { data: graphData, isLoading: graphDataLoading } = useQuery({
    queryKey: ['graphData'],
    queryFn: ({ queryKey }) => {
      if (permissions?.Users) {
        return getRedeemReport()
      }
    },
    refetchOnWindowFocus: false,
    select: (res) => res?.data
  })  
  const handleDownloadClick = async () => {
    try {
      let filename = 'Redeem_Request';

      if (search) {
        filename += `_${search}`;
      }

      const formattedStartDate = formattedDate(startDate)
      const formattedEndDate = formattedDate(endDate)

      if (selectedAction == 'all') {
        filename += `_${formattedStartDate}_${formattedEndDate}`;
      } else {
        filename += `_${selectedAction}_${formattedStartDate}_${formattedEndDate}`;
      }
      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvClick(url, filename)
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  const handlePlayerTableSorting = (param) => {
    if (param.value === orderBy) {
      setSort(sort === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(param.value)
      setSort('asc');
    }
  }
  return (
    <>
      <Row className='mb-3'>
        <Col xs={12}>
          <h3>Redeem Requests</h3>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col xs={6}>
          {graphData && <LineChart graphTitle={'Request Count Graph'} graphData={graphData} />}
        </Col>
        <Col xs={6}>
          {graphData && <BarChart graphTitle={'Redemption Approve Pending Graph'} graphData={graphData} />}
        </Col>
        <Col xs={3}>
          {/* <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Skrill Balance</h6>
            <span >$100.000</span>
          </div> */}
        </Col>
      </Row>

      {/* <Row className="mt-5">
        <PreApprovedRedeemRequests />
      </Row>
      <Row className="mt-5">
        <LongPendingRequests />
      </Row> */}

      <Row className="mt-5">
        <h4>Remaining Pending Requests</h4>
        <Row className='mt-4'>
          <Col xs='12' sm='6' lg='3' className='mb-3'>
            <Form.Label
            >
              Search
            </Form.Label>
            <Form.Control
              type='search'
              value={search}
              placeholder='Search By Email'
              onChange={(event) => {
                setPage(1);
                setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ""));
              }}
            />
          </Col>
          <Col xs='12' sm='6' lg='3' className='mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => {
                setPage(1);
                setSelectedAction(e.target.value);
              }}
              value={selectedAction}
            >
              {statusOptions &&
                statusOptions?.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
            </Form.Select>
          </Col>
          {/* <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          <DateRangePicker width='auto' state={state} setState={setState} timeZoneCode={timezoneOffset} startDate = {startDate} endDate={endDate}/>
        </Col> */}
          <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              Start Date
            </Form.Label>
            <Datetime
              value={startDate}
              onChange={(date) => setStartDate(date)}
              timeFormat={false}
            />
          </Col>
          <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              End Date
            </Form.Label>
            <Datetime
              value={endDate}
              onChange={(date) => setEndDate(date)}
              timeFormat={false}
            />
          </Col>
          <Col xs='12' sm='6' lg='1' className='d-flex align-items-end mt-2 mt-sm-0 mb-0 mb-lg-3'>
            <Trigger message='Reset Filters' id={"redo"} />
            <Button
              id={"redo"}
              variant='success'
              onClick={() => {
                setSearch("");
                setSelectedAction("pending");
                setLimit(15);
                setPage(1);
                setStartDate(getStartDateMonthsAgo(3)),
                  setEndDate(new Date())
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>

            <Trigger message='Download as CSV' id={'csv'} />
            <Button
              id={'csv'}
              variant='success' style={{ marginLeft: '10px' }}
              onClick={handleDownloadClick}
            >
              {downloadInProgress ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                <FontAwesomeIcon icon={faFileDownload} />
              )}
            </Button>
          </Col>
        </Row>

        <Table
          bordered
          striped
          responsive
          hover
          size='sm'
          className='text-center mt-4'
        >
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() => h.value !== '' && handlePlayerTableSorting(h)}
                  style={{
                    cursor: 'pointer'
                  }}
                  className={
                    selected(h)
                      ? 'border-3 border border-blue'
                      : ''
                  }
                >
                  {h.labelKey}{' '}
                  {selected(h) &&
                    (sort === 'asc'
                      ? (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleUp}
                          onClick={() => setSort('desc')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                      )
                      : (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleDown}
                          onClick={() => setSort('asc')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                      ))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {transactionData &&
              transactionData?.rows?.map(
                ({
                  transactionId,
                  userTotalPurchase,
                  email,
                  amount,
                  ngr,
                  cancelRedemptionCount,
                  lastApprovedRedeemDate,
                  lastApprovedRedeemAmount,
                  zipCode,
                  ipLocation,
                  moreDetails,
                  actionableEmail,
                  isFloridaOrNewYorkUser,
                  userId,
                  status,
                  paymentProvider,
                  withdrawRequestId,
                  lastRunAt
                }) => {
                  return (
                    <tr key={transactionId} style={{
                      background: isFloridaOrNewYorkUser ? '#ffa8a8' : ''
                    }}>
                      <td>{ngr > 0 ? <span style={{ color: 'green' }}>{ngr?.toFixed(2)}</span> : <span style={{ color: 'red' }}>{ngr?.toFixed(2)}</span>}</td> {/* NGR Value */}
                      {/* <td>{userTotalPurchase ? userTotalPurchase?.toFixed(2) : '-'}</td>  */}
                      <td>
                        <Link to={`/admin/player-details/${userId}`}>
                          {email}
                        </Link> {/* Email */}
                      </td>
                      <td>{actionableEmail}</td> {/* Cancel Redemption Count */}
                      <td>{amount?.toFixed(2)}</td> {/* Amount */}

                      <td>{cancelRedemptionCount}</td> {/* Cancel Redemption Count */}
                      <td>{lastApprovedRedeemDate ? getDateTime(convertToTimeZone(lastApprovedRedeemDate, timezoneOffset)) : '-'}</td>
                      {/* <td>{lastRunAt ? getDateTime(convertToTimeZone(lastRunAt, timezoneOffset)) : '-'}</td> */}
                      {/* <td>{lastApprovedRedeemAmount?.toFixed(2)}</td> Last Approved Redeem Amount */}
                      <td>{zipCode}</td> {/* Zip Code */}
                      <td>{ipLocation || 'N/A'}</td> {/* IP Location */}
                      <td>
                        {status === 0 ? (
                          <>
                            <Trigger
                              message='Approve'
                              id={transactionId + "edit"}
                            />
                            <Button
                              disabled={status !== 0 || updateLoading}
                              id={transactionId + "edit"}
                              className='m-1'
                              size='sm'
                              variant='success'
                              onClick={() => {
                                setType('approved')
                                setApproveModal(true)
                                setRedeemRequest({ userId, withdrawRequestId, paymentProvider })
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                          </>
                        ) : status === 1 ? (
                          <span className='success'>Approved</span>
                        ) : status === 2 ? (
                          <span className='danger'>Cancelled</span>
                        ) : status === 7 ? (
                          <button className='btn btn-success btn-sm' onClick={() => handelFetchStatus(withdrawRequestId)}>Check Status</button>
                        ) : status === 6 ? (
                          <span className='danger'>Declined</span>
                        ) : (
                          <span className='danger'>Failed</span>
                        )}
                        {status === 0 && (
                          <>
                            <Trigger
                              message='Cancel'
                              id={transactionId + "Cancel"}
                            />
                            <Button
                              disabled={status !== 0 || updateLoading}
                              id={transactionId + "Cancel"}
                              className='m-1'
                              size='sm'
                              variant='danger'
                              onClick={() => {
                                setType('rejected')
                                setApproveModal(true)
                                setRedeemRequest({ userId, withdrawRequestId, paymentProvider })
                              }}
                            >
                              <FontAwesomeIcon icon={faTimesSquare} />
                            </Button>
                          </>
                        )}
                      </td>
                      <td><Button style={{ padding: '3px 8px' }} onClick={() => {
                        getMoreDetail({ transactionId, userId })
                        // setRedeemMoreDetail(true)
                        // setReasonData(moreDetails)
                        // setMoreDetailData({ amount, ngr, lastApprovedRedeemDate, email, actionableEmail, lastApprovedRedeemAmount })
                      }}>More Detail</Button></td>
                    </tr>
                  );
                }
              )}

            {transactionData?.count === 0 && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      {loading && <InlineLoader />}
      {transactionData?.count !== 0 && (
        <PaginationComponent
          page={transactionData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      {approveModal &&
        <ApproveRedeemConfirmation
          show={approveModal}
          setShow={setApproveModal}
          handleYes={handleApproveRequest}
          redeemRequest={redeemRequest}
          type={type}
        />}
      {redeemMoreDetail &&
        <RedeemMoreDetail
          show={redeemMoreDetail}
          setShow={setRedeemMoreDetail}
          handleYes={handleRedeem}
          moreDetailData={moreDetailData}
          reasonData={reasonData}
        />}
    </>
  );
};

export default WithdrawRequests;
