import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useDidMountEffect from "../../../utils/useDidMountEffect";
import { useGetPackagesListingQuery } from "../../../reactQuery/hooks/customQueryHook";
import { useTranslation } from "react-i18next";
import { errorHandler, useDeletePackages } from "../../../reactQuery/hooks/customMutationHook";
import { toast } from "../../../components/Toast";

const usePackagesListing = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("packageId");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [over, setOver] = useState(false);
  const [active, setActive] = useState();
  const [enabled, setEnabled] = useState(false);
  const [show, setShow] = useState(false);
  const [hot, setHot] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isVisibleInStore, setIsVisibleInStore] = useState("");
  const { t } = useTranslation(["packages"]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [packageId, setPackageId] = useState("");
  const queryClient = useQueryClient();

  const isInitialRender = useDidMountEffect();
  const {
    data,
    refetch: fetchData,
    isLoading: loading,
  } = useGetPackagesListingQuery({
    params: {
      limit,
      pageNo: page,
      orderBy,
      sort,
      search,
      hot,
      isActive,
      isVisibleInStore,
    },
    enabled,
  });

  const totalPages = Math.ceil(data?.count / limit);

  useEffect(() => {
    setEnabled(true);
  }, []);

  const handleShow = (id, active) => {
    setActive(!active);
    setShow(true);
  };

  const handleYes = () => {
    setShow(false);
  };
  const selected = (h) =>
    orderBy === h.value &&
    h.labelKey !== "Role" &&
    h.labelKey !== "Status" &&
    h.labelKey !== "Action" &&
    h.labelKey !== "Group";

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchData();
        } else {
          setPage(1);
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    !isInitialRender && fetchData();
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchData();
  }, [limit, orderBy, sort]);

  const { mutate: deletePackage } = useDeletePackages({
    onSuccess: ({ data }) => {
      if (data.success) {
        if (data.message) {
          toast(data.message, "success")
          fetchData()
        }
        
      }
      setDeleteModalShow(false);
    },
    onError: (error) => {
      errorHandler(error)
      setDeleteModalShow(false);
    },
  });

  const handleDeleteYes = () => {
    if (!packageId) {
      toast("Package ID is missing", { type: "error" });
      return;
    }
    deletePackage({ packageId: packageId });
  };

  const handleDeleteModal = (id) => {
    setPackageId(id);
    setDeleteModalShow(true);
  };

  return {
    loading,
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
    sort,
    setSort,
    search,
    setSearch,
    show,
    setShow,
    over,
    setOver,
    data,
    totalPages,
    handleShow,
    handleYes,
    selected,
    active,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    handleDeleteModal,
    setHot,
    setIsActive,
    setIsVisibleInStore,
    hot,
    isActive,
    isVisibleInStore,
    fetchData,
    t,
  };
};

export default usePackagesListing;
